













































































	import {
		Component,
		Vue
	} from "vue-property-decorator";
	import {
		apiSystemlogList
	} from "@/api/setting"
	import {
		RequestPaging
	} from "@/utils/util"
	import LsPagination from "@/components/ls-pagination.vue"
	import ExportData from '@/components/export-data/index.vue'
	@Component({
		components: {
			LsPagination,
			ExportData
		},
	})
	export default class Journal extends Vue {
		/** S Data **/

		pickerOptions = {
			shortcuts: [{
					text: "最近一周",
					onClick(picker: any) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit("pick", [start, end]);
					},
				},
				{
					text: "最近一个月",
					onClick(picker: any) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit("pick", [start, end]);
					},
				},
				{
					text: "最近三个月",
					onClick(picker: any) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit("pick", [start, end]);
					},
				},
			],
		}

		tableData: Array<object> = [];

		pager: RequestPaging = new RequestPaging()

		// 查询表单
		form: any = {
			admin_name: '', // 管理员
			url: '', // 访问链接
			ip: '', // 来源IP
			type: '', // 访问方式
			start_time: '', // 日志时间开始
			end_time: '', // 日志时间结束
		}
		
		$refs!: { formRef: any } 
		
		apiSystemlogList = apiSystemlogList
		/** E Data **/

		/** S Methods **/
		//获取日志列表数据
		getJournalList() {
			// 分页请求
			this.pager
				.request({
					callback: apiSystemlogList,
					params: this.form
				})
		}
		// 重置按钮
		onReset() {
      for (const key in this.form) {
        this.$set(this.form, key, '')
      }
      this.getJournalList()
		}
		// 拆分时间
		splitTime() {
			console.log(this.tableData)
			if (this.tableData != null) {
				this.form.start_time = this.tableData[0]
				this.form.end_time = this.tableData[1]
			}

		}
		/** E Methods **/

		/** S Life Cycle **/
		created() {
			this.getJournalList()
		}
		/** E Life Cycle **/
	}
